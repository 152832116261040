exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-messengers-js": () => import("./../../../src/pages/messengers.js" /* webpackChunkName: "component---src-pages-messengers-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shevchyk-js": () => import("./../../../src/pages/shevchyk.js" /* webpackChunkName: "component---src-pages-shevchyk-js" */),
  "component---src-templates-category-page-index-js": () => import("./../../../src/templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/Product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

